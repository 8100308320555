import Footer from "../../components/Footer";
import React from "react";
import { useEffect, useState } from "react";
import { useLocation, Link } from "@reach/router";
import { useDispatch, useSelector } from "react-redux";
import { updateUser } from "../../../store/actions";
import { uploadProfilePic, updateCreateUser } from "../../../store/actions/thunks";
import * as selectors from "../../../store/selectors";
import ProfileComponent from "../../components/ProfileComponent";

const Profile = () => {
	const dispatch = useDispatch();
	const userState = useSelector(selectors.usersState);
	console.log("userState:", userState);
	const user = userState?.userDetail.data ? userState.userDetail.data : [];

	const [showform, btn_show_form] = useState(false);
	const [selectedFile, setSelectedFile] = useState();
	const [preview, setPreview] = useState();
	const [formValues, setFormValues] = useState(user);

	const handleChange = (e) => {
		const { name, value } = e.target;
		setFormValues({ ...formValues, [name]: value });
		user[name] = value;
	};

	const onSelectFile = (e) => {
		if (!e.target.files || e.target.files.length === 0) {
			setSelectedFile(undefined);
			return;
		}
		setSelectedFile(e.target.files[0]);
		user.profileImgSrc = "/useruploads/" + user?.wallet + "/" + e.target.value;
		setFormValues({ ...formValues, ["profileImgSrc"]: e.target.value });
	};

	const submitForm = (e) => {
		// alert("Submitted");
		e.preventDefault();
		//const errors = validate(formValues);
		//setFormErrors(errors);
		//if (errors.length > 0)
		//	return;

		const inputVar = {
			input: { file: selectedFile, path: user?.profileImgSrc, wallet: user?.wallet }
		};
		dispatch(uploadProfilePic(inputVar));
		
		dispatch(updateCreateUser(user));
		dispatch(updateUser.success(user));
		alert("Profile has been updated.");

		
	};
	useEffect(() => {
		if (!selectedFile) {
			setPreview(undefined);
			return;
		}
		// create the preview
		const objectUrl = URL.createObjectURL(selectedFile);
		setPreview(objectUrl);

		// free memory when ever this component is unmounted
		return () => URL.revokeObjectURL(objectUrl);
	}, [selectedFile]);

	const location = useLocation();

	//destructuring pathname from location
	const { pathname } = location;

	//Javascript split method to get the name of the path in array
	const splitLocation = pathname.split("/");

  return (
		<div>
			<section className="container">
				<div className="view-account">
					<div className="side-bar">
						<div className="user-info">
							{preview != null ? (
								<img className="img-profile img-circle img-responsive center-block" src={preview} alt={user?.username} />
							) : (
								<img className="img-profile img-circle img-responsive center-block" src={user?.profileImgSrc} alt={user?.username} />
							)}
							<ul className="meta list list-unstyled">
								<h3 className="name">{user.username}</h3>
								<li className="email">
									<b>{user.wallet} </b>
								</li>
								<li className="activity">Last logged in: Today at 2:18pm</li>
							</ul>
						</div>
						<nav className="side-menu">
							<ul className="nav">
								<li className={splitLocation[1] === "Profile" ? "active" : ""}>
									<Link to="/Profile">
										<span className="fa fa-user"></span> Profile
									</Link>
								</li>
								<li className={splitLocation[1] === "CryptoAssets" ? "active" : ""}>
									<Link to="/CryptoAssets">
										<span className="fa fa-envelope"></span> NFTs
									</Link>
								</li>
								<li className={splitLocation[1] === "Statistics" ? "active" : ""}>
									<Link to="/Statistics">
										<span className="fa fa-th"></span> Statistics
									</Link>
								</li>
								<li className={splitLocation[1] === "Settings" ? "active" : ""}>
									<Link to="/Settings">
										<span className="fa fa-cog"></span> Settings
									</Link>
								</li>
							</ul>
						</nav>
					</div>
					<div className="content-panel">
						<h2 className="title">Profile</h2>
						<form className="form-horizontal">
							<div className="form-group avatar">
								<div className="form-inline col-md-10 col-sm-9 col-xs-12">
									<input type="file" name="newProfileImg" className="file-uploader pull-left" value="" onChange={onSelectFile} title="New profile img" />
								</div>
							</div>
							<div className="form-group">
								<label className="col-md-2 col-sm-3 col-xs-12 control-label">Username</label>
								<div className="col-md-10 col-sm-9 col-xs-12">
									<input type="text" name="username" className="form-control" value={user.username} onChange={handleChange} />
								</div>
							</div>

							<div className="form-group">
								<label className="col-md-2 col-sm-3 col-xs-12 control-label">First Name</label>
								<div className="col-md-10 col-sm-9 col-xs-12">
									<input type="text" name="firstname" className="form-control" value={user.firstname} onChange={handleChange} />
								</div>
							</div>
							<div className="form-group">
								<label className="col-md-2 col-sm-3 col-xs-12 control-label">Last Name</label>
								<div className="col-md-10 col-sm-9 col-xs-12">
									<input type="text" name="lastname" className="form-control" value={user.lastname} onChange={handleChange} />
								</div>
							</div>
							<div className="form-group">
								<label className="col-md-2 col-sm-3 col-xs-12 control-label">Bio</label>
								<div className="col-md-10 col-sm-9 col-xs-12">
									<input type="text" name="bio" className="form-control" value={user?.bio} onChange={handleChange} />
								</div>
							</div>
							<br />
							<h3 className="title">Contact Info</h3>
							<div className="form-group">
								<label className="col-md-2  col-sm-3 col-xs-12 control-label">Email</label>
								<div className="col-md-10 col-sm-9 col-xs-12">
									<input type="email" name="email" className="form-control" value={user.email} onChange={handleChange} />
								</div>
							</div>
							<div className="form-group">
								<label className="col-md-2  col-sm-3 col-xs-12 control-label">Twitter</label>
								<div className="col-md-10 col-sm-9 col-xs-12">
									<input type="text" name="twitter" className="form-control" value="_twitter" onChange={handleChange} />
								</div>
							</div>
							<div className="form-group">
								<label className="col-md-2  col-sm-3 col-xs-12 control-label">Discord</label>
								<div className="col-md-10 col-sm-9 col-xs-12">
									<input type="text" name="discord" className="form-control" value="_discord" onChange={handleChange} />
								</div>
							</div>
							<div className="form-group">
								<div className="col-md-10 col-sm-9 col-xs-12 col-md-push-2 col-sm-push-3 col-xs-push-0">
									<input className="btn-main" type="submit" value="Update Profile" onClick={(e) => submitForm(e)} />
								</div>
							</div>
						</form>
					</div>
				</div>
			</section>

			<Footer />
		</div>
	);
};
export default Profile;