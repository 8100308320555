import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import "./assets/animated.css";
import '../node_modules/@fortawesome/fontawesome-free/'
import '../node_modules/elegant-icons/style.css';
import '../node_modules/et-line/style.css';
import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import '../node_modules/bootstrap/dist/js/bootstrap.js';
import './assets/style.scss';
import { ApolloProvider } from '@apollo/client';
import client from './store/apollo';


//redux store
import { Provider } from 'react-redux';
import store from './store';



ReactDOM.render(

    <Provider store={store}>
        <ApolloProvider client={client}>
            <App />
        </ApolloProvider>
    </Provider>,
    document.getElementById('root')
);
