import thunk from "redux-thunk";
import rootReducer from "./reducers";
import { createGQLClient } from './apollo';

import { createStore, applyMiddleware} from "redux";

const store = createStore(
    rootReducer,
    applyMiddleware(
        thunk.withExtraArgument({ client: createGQLClient()})
    ));

// const store = createStore(
// 	combineReducers({
// 		rootReducer: rootReducer,
// 		// apollo: client.reducer(),
// 	}),
// 	{}, // initial state
// 	applyMiddleware(thunk)
// );

export default store;
