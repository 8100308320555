import { getType } from 'typesafe-actions';
import * as actions from '../actions';
import { initEntityState, entityLoadingStarted, entityLoadingSucceeded, entityLoadingFailed } from '../utils';

export const defaultState = {
    userDetail: initEntityState(null)
};

const states = (state = defaultState, action) => {
    switch (action.type) {

        case getType(actions.getUserDats.request):
            return { ...state, userDetail: entityLoadingStarted(state.userDetail, action.payload) };
        case getType(actions.getUserDats.success):
            return { ...state, userDetail: entityLoadingSucceeded(state.userDetail, action.payload) };
        case getType(actions.getUserDats.failure):
            return { ...state, userDetail: entityLoadingFailed(state.userDetail) };

        case getType(actions.getUserBalance.request):
            return { ...state, userBalance: entityLoadingStarted(state.userBalance, action.payload) };
        case getType(actions.getUserBalance.success):
            return { ...state, userBalance: entityLoadingSucceeded(state.userBalance, action.payload) };
        case getType(actions.getUserBalance.failure):
            return { ...state, userBalance: entityLoadingFailed(state.userBalance) };

        case getType(actions.updateUser.request):
            return { ...state, userDetail: entityLoadingStarted(state.userDetail, action.payload) };
        case getType(actions.updateUser.success):
            return { ...state, userDetail: entityLoadingSucceeded(state.userDetail, action.payload) };
        case getType(actions.updateUser.failure):
            return { ...state, userDetail: entityLoadingFailed(state.userDetail) };

        default:
            return state;
    }
};

export default states;
