import React from 'react';
import ColumnNewThreeColRedux from '../components/ColumnNewThreeColRedux';
import Footer from '../components/Footer';
import { createGlobalStyle } from 'styled-components';
import CheckboxFilter from '../components/CheckboxFilter';

const Marketplace=(props) => (
<div>
  <section className='container'>
        <div className='row'>
                <div className="spacer-double"></div>
          <div className='col-md-3'>
                    <CheckboxFilter catagory={props.catagory} />
          </div>
          <div className="col-md-9">
            <ColumnNewThreeColRedux/>
          </div>
        </div>
      </section>


  <Footer />
</div>

);

export default Marketplace;


//     const { data, loading, error } = useQuery(GET_USERS_INFO);

//     if (loading) return <p>Loading...</p>;
//     if (error) return <p>Error</p>;


//     return (
//         <React.Fragment>
//             <h1>User</h1>

//             <p>
//                 {" "}
//       Users in the Crypto Blox system.
//     </p>
//             <div className="container">
//                 {data &&
//                     data.user &&
//                     data.user.map((user, index) => (
//                         <div key={index} className="card">

//                             <div class="card-body">
//                                 <h3>{user.firstname} {user.lastname}</h3>
//                                 <p>
//                                     {user.nfts && user.nfts.length !== 0 && (
//                                         <p>
//                                             {" "}
//                     NFTs:
//                                             {user.nfts.map((n, indx) => {
//                                                 return <p key={indx}> {n.displayname} </p>;
//                                             })}
//                                         </p>
//                                     )}
//                                 </p>
//                             </div>
//                         </div>
//                     ))}
//             </div>
//         </React.Fragment>
//     );
