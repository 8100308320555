import React, {useCallback, useEffect, useState} from "react";
import web3 from "../../getWeb3";
import Breakpoint, { BreakpointProvider, setDefaultBreakpoints } from "react-socks";
// import { header } from 'react-bootstrap';
import { Link, navigate } from "@reach/router";
import useOnclickOutside from "react-cool-onclickoutside";
import { categories } from "../components/constants/filters";
import { filterCategories, getUserBalance, getUserDats } from "../../store/actions";
import { fetchUserBalance, fetchUser } from "../../store/actions/thunks";
import * as selectors from '../../store/selectors';
import { useDispatch, useSelector } from "react-redux";
import {  useQuery, useLazyQuery } from "@apollo/client";
import { gql } from "graphql-tag"

setDefaultBreakpoints([{ xs: 0 }, { l: 1199 }, { xl: 1200 }]);

const NavLink = (props) => (
  <Link
    {...props}
    getProps={({ isCurrent }) => {
      return {
        className: isCurrent ? "active" : "non-active",
      };
    }}
  />
);

const Header = () => {
    const [openMenu1, setOpenMenu1] = React.useState(false);
    const [openMenu2, setOpenMenu2] = React.useState(false);

    const dispatch = useDispatch();

    const handleBtnClick1 = () => {
        setOpenMenu1(!openMenu1);
    };
    const handleBtnClick2 = () => {
        setOpenMenu2(!openMenu2);
    };
    const closeMenu1 = () => {
        setOpenMenu1(false);
    };
    const closeMenu2 = () => {
        setOpenMenu2(false);
    };
    const ref1 = useOnclickOutside(() => {
        closeMenu1();
    });
    const ref2 = useOnclickOutside(() => {
        closeMenu2();
    });
    const [showmenu, btn_icon] = useState(false);
    const [showpop, btn_icon_pop] = useState(false);
    const [shownot, btn_icon_not] = useState(false);
    const closePop = () => {
        btn_icon_pop(false);
    };
    const closeNot = () => {
        btn_icon_not(false);
    };
    const refpop = useOnclickOutside(() => {
        closePop();
    });
    const refpopnot = useOnclickOutside(() => {
        closeNot();
    });



    useEffect(() => {
        const headers = document.getElementById("myHeader");
        const totop = document.getElementById("scroll-to-top");
        const sticky = headers.offsetTop;
        const scrollCallBack = window.addEventListener("scroll", () => {
            btn_icon(false);
            if (window.pageYOffset > sticky) {
                headers.classList.add("sticky");
                totop.classList.add("show");
            } else {
                headers.classList.remove("sticky");
                totop.classList.remove("show");
            }
            if (window.pageYOffset > sticky) {
                closeMenu1();
            }
        });
        return () => {
            window.removeEventListener("scroll", scrollCallBack);
        };
    }, []);


    useEffect(() => {
        const checkConnection = async () => {
            // Check if User is already connected by retrieving the accounts
            if (web3) {
                web3.eth.getAccounts().then(async (addr) => {
                    // Set User account into state
                    console.log("getAccounts:", addr);
                    if (addr && addr.length > 0 && currentAccount !== addr[0]) {
                        console.log("set currentAccount:", addr[0]);
                        setCurrentAccount(addr[0]);
                    } else {
                        console.log("set currentAccount null");
                        setCurrentAccount(null);
                    }
                    console.log("currentAccount", currentAccount);
                });
            };
        }
        checkConnection();
    }, []);


    //Setup a stateful variable and update function for current user wallet
    const [currentAccount, setCurrentAccount] = useState(null);
    
    const userState = useSelector(selectors.usersState);
    const user = userState.userDetail?.data ? userState.userDetail.data : null;
    const userBalance = userState.userBalance?.data ? userState.userBalance.data : "";


    //Wire account change to lazy gql const data, if the query changes the account handle function is run
    useEffect(() => {
        if (currentAccount?.length > 0) {
            console.log("Account changed:", currentAccount);
            //getUser();
            dispatch(fetchUser(currentAccount));
            dispatch(fetchUserBalance(currentAccount));
            console.log("userState", userState);
            console.log("user", user);
        }
    }, [currentAccount]);



    //Funtion to set current account
    const ConnectWallet = () => {
        if (web3) {
            window.ethereum.request({ method: "eth_requestAccounts" }).then(res => {
                web3.eth.getAccounts().then(accounts => {
                    if (accounts && accounts.length > 0)
                        setCurrentAccount(accounts[0]);
                    //alert(accounts);
                });
            });
        } else {
            alert("Please install MetaMask extension")
        };
    }

 
    const AccountDetails = (
			<div className="logout">
				<div className="de-menu-profile" onClick={() => btn_icon_pop(!showpop)} ref={refpop}>
					{user !== null && <img src={ user?.profileImgSrc} alt="" />}
					{showpop && (
						<div className="popshow">
							<div className="d-name">
                                <h2>
                                    <b>{user.username ? user.username : "Profile"}</b>
                                </h2>
							</div>
							<div className="d-balance">
								<h4>Balance</h4>
								{userBalance && <b>{userBalance} MATIC</b>}
							</div>
							<div className="d-wallet">
								<h4>My Wallet</h4>
								<span id="wallet" className="d-wallet-address">
									{currentAccount}
								</span>
							</div>
							<div className="d-line"></div>
							<ul className="de-submenu-profile">
								<li>
									<span>
										<i className="fa fa-user"></i> <NavLink to="/Profile">Profile</NavLink>
									</span>
								</li>
								<li>
									<span
										onClick={() => {
											var z = window.confirm("Are you sure you want to disconnect? This will disconnect you from the site, you still have to manually disconnect your account from wallet extension.");
											if (z == true) {
												setCurrentAccount(null);
												navigate("/");
											} else {
												console.log("Disconnect canceled...");
											}
										}}
									>
										<i className="fa fa-sign-out"></i> <a>Sign out</a>
									</span>
								</li>
							</ul>
						</div>
					)}
				</div>

				<div className="de-menu-notification" onClick={() => btn_icon_not(!shownot)} ref={refpopnot}>
					<div className="d-count">0</div>
					<i className="fa fa-bell"></i>
					{shownot && (
						<div className="popshow">
							<div className="de-flex">
								<h4>Notifications</h4>
								<span className="viewaall">Show all</span>
							</div>
							<ul>
								<li>
									<div className="mainnot">
										<img className="lazy" src="../../LOGO2-1.ico" alt="" />
										<div className="d-desc">
											<span className="d-name">
												<br />
												<b>No new notifications</b>
											</span>
											<span className="d-time"></span>
										</div>
									</div>
								</li>
							</ul>
						</div>
					)}
				</div>
			</div>
		);

    
    const MetamaskButton = (
        <div className="connect-wal" onClick={() => ConnectWallet()}>
            {/* {!user && <a> Connect wallet</a>}
            {user && <a>Loading ...</a>} */}
            <a> Connect wallet</a>
        </div>
    );
    
    return (
			<header id="myHeader" className="navbar white">
				<div className="container-fluid">
					<div className="row w-100-nav">
						<div className="logo px-0">
							<div className="navbar-title navbar-item">
								<NavLink to="/">
									<img src="/img/LOGO2-1.png" className="img-fluid" alt="#" />
								</NavLink>
							</div>
						</div>

						{/* <div className='search'>
                        <input id='quick_search' className='xs-hide' name='quick_search' placeholder='search item here...' type='text' />
                    </div> */}

						<BreakpointProvider>
							<Breakpoint l down>
								{showmenu && (
									<div className="menu">
										<div className="navbar-item">
											<div ref={ref1}>
												<div className="dropdown-custom dropdown-toggle btn" onClick={handleBtnClick1}>
													Home
												</div>
												{openMenu1 && (
													<div className="item-dropdown">
														<div className="dropdown" onClick={closeMenu1}>
															<NavLink to="/Home">Home</NavLink>
															<NavLink to="/Explore">TBD Page</NavLink>
															<NavLink to="/About">About</NavLink>
														</div>
													</div>
												)}
											</div>
										</div>
										<div className="navbar-item">
											<div ref={ref2}>
												<div className="dropdown-custom dropdown-toggle btn" onClick={handleBtnClick2}>
													Categories
												</div>
												{openMenu2 && (
													<div className="item-dropdown">
														<div className="dropdown" onClick={closeMenu2}>
															{categories.map((item, index) => (
																<NavLink to={"/Marketplace/" + item.value} catagory={item.value}>
																	{item.label}
																</NavLink>
															))}
														</div>
													</div>
												)}
											</div>
										</div>
										<div className="navbar-item">
											<NavLink to="/CreateNFT" onClick={() => btn_icon(!showmenu)}>
												Create
											</NavLink>
										</div>
									</div>
								)}
							</Breakpoint>

							<Breakpoint xl>
								<div className="menu">
									<div className="navbar-item">
										<NavLink to="/Home" ref={ref1} onClick={() => btn_icon(!showmenu)}>
											<div className="dropdown-custom dropdown-toggle btn" onMouseEnter={handleBtnClick1} onMouseLeave={closeMenu1}>
												Home
												<span className="lines"></span>
												{openMenu1 && (
													<div className="item-dropdown">
														<div className="dropdown" onClick={closeMenu1}>
															<NavLink to="/Home">Home</NavLink>
															<NavLink to="/Explore">TBD page</NavLink>
															<NavLink to="/About">About</NavLink>
														</div>
													</div>
												)}
											</div>
										</NavLink>
									</div>
									<div className="navbar-item">
										<NavLink to="/Marketplace" ref={ref2} onClick={() => btn_icon(!showmenu)}>
											<div className="dropdown-custom dropdown-toggle btn" onMouseEnter={handleBtnClick2} onMouseLeave={closeMenu2}>
												Categories
												<span className="lines"></span>
												{openMenu2 && (
													<div className="item-dropdown">
														<div className="dropdown" onClick={closeMenu2}>
															{categories.map((item, index) => (
																<NavLink to={"/Marketplace/" + item.value} catagory={item.value}>
																	{item.label}
																</NavLink>
															))}
														</div>
													</div>
												)}
											</div>
										</NavLink>
									</div>
									<div className="navbar-item">
										<NavLink to="/CreateNFT">
											Create
											<span className="lines"></span>
										</NavLink>
									</div>
								</div>
							</Breakpoint>
						</BreakpointProvider>

						<div className="mainside">{currentAccount === null || currentAccount?.length === 0 ? MetamaskButton : AccountDetails}</div>
					</div>

					<button className="nav-icon" onClick={() => btn_icon(!showmenu)}>
						<div className="menu-line white"></div>
						<div className="menu-line1 white"></div>
						<div className="menu-line2 white"></div>
					</button>
				</div>
			</header>
		);
};

export default Header;
