import React from 'react'
import { createGlobalStyle } from 'styled-components';
import Footer from '../components/Footer';

const GlobalStyles = createGlobalStyle`
  header#myHeader.navbar .search #quick_search{
    background: rgba(255, 255, 255, .1);
  }
  header#myHeader .logo .d-block{
    display: none !important;
  }
  header#myHeader .logo .d-none{
    display: none !important;
  }
  header#myHeader .logo .d-3{
    display: block !important;
  }
  footer.footer-light .subfooter span img.d-1{
    display: none !important;
  }
  footer.footer-light .subfooter span img.d-3{
    display: inline-block !important;
  }
  .de_countdown{
    right: 10px;
  }
  .author_list_pp{
    margin-left:0;
  }
  footer.footer-light .subfooter{
    border-top: 1px solid rgba(255,255,255,.1);
  }
`;



const Explore = () => (
	<div>
		<section className="jumbotron breadcumb no-bg">
			<div className="mainbreadcumb">
				<div className="container">
					<div className="row m-10-hor">
						<div className="col-12">
							<h1 className="text-center">Explore</h1>
						</div>
					</div>
				</div>
			</div>
		</section>

		<section className="container">
			<div className="row">
				<div className="newlayo">
					<h1 className="teal">Site statistics, promo links, etc.</h1>
					<h1 className="teal">Site statistics, promo links, etc.</h1>
					<h1 className="teal">Site statistics, promo links, etc.</h1>
					<h1 className="teal">Site statistics, promo links, etc.</h1>
					<h1 className="teal">Site statistics, promo links, etc.</h1>
				</div>
			</div>
		</section>

		<Footer />
	</div>
);
  
  export default Explore;
  
  