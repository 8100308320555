import { ApolloClient } from "@apollo/client";
import { InMemoryCache } from 'apollo-cache-inmemory';
import { withClientState } from 'apollo-link-state';
import { createUploadLink } from "apollo-upload-client";

//const stateLink = withClientState({
//	cache
//});

export const createGQLClient = () => {
    const cache = new InMemoryCache({
        addTypename: false,
        resultCaching: false,
    });
    //const gqlUri = "https://localhost:5001/graphql";
    const gqlUri = "http://api.blockchainblox.com/graphql";

    const client = new ApolloClient({
        // Provide required constructor fields
        uri: gqlUri,
        cache: cache,
        //link: createUploadLink({
        //    uri: "http://api.blockchainblox.com/graphql",
        //    includeUnusedVariables: false,
        //    credentials: 'include',
        //}),
        link: createUploadLink({ uri: gqlUri }),
        defaultOptions: {
            watchQuery: {
                fetchPolicy: 'no-cache',
            },
            mutate: {
                fetchPolicy: 'no-cache',
            },
        },
    });

    const query = (name, query, variables) => {
        return client
            .query({
                query,
                variables,
                fetchPolicy: 'no-cache',
            })
            .then(({ data }) =>  data[name] );
    };

    const mutate = (name, mutation, variables) => {
        return client
            .mutate({
                mutation,
                variables,
            })
            .then(({ data }) => data[name]);
    };

    return { query, mutate };
};
//const client = new ApolloClient({
//	uri: "http://api.blockchainblox.com/graphql",
//	cache: cache
//});


export default createGQLClient;