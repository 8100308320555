import React, { useEffect, useState } from "react";
import Footer from "../components/Footer";
import { categories, collections } from "../components/constants/filters";
import { useSelector, useDispatch } from "react-redux";
import * as selectors from "../../store/selectors";
import { navigate } from "@reach/router";


const ProfileCreate = (props) => {
	const initialValues = {
		firstname: "",
		lastname: "",
		username: "",
		email: "",
		bio: ""
	};

	const [formValues, setFormValues] = useState(initialValues);
	const [formErrors, setFormErrors] = useState({initial: "Please fill in required details"});
	const [isSubmit, setIsSubmit] = useState(false);


	const handleChange = (e) => {
		const { name, value } = e.target;
		setFormValues({ ...formValues, [name]: value });
	};


	const handleSubmit = (e) => {
		e.preventDefault();
		setFormErrors(validate(formValues));
		setIsSubmit(true);

		console.log(formErrors);
		console.log(isSubmit)
	};

	useEffect(() => {
		console.log(formErrors);
		if (Object.keys(formErrors).length === 0 && isSubmit) {
			console.log(formValues);
			navigate("/Profile")
		}
	});

	const validate = (values) => {
		const errors = {};
        if (!values.email) {
            errors.email = "Email required";
        }
		if (!values.username) {
			errors.username = "Username required";
		}
		if (Object.keys(errors).length > 0) {
			alert("Please correct the errors and resubmit");
		}

		return errors;
	};


	//Load user state

	const dispatch = useDispatch();
	const userState = useSelector(selectors.usersState);
	console.log("userState:", userState);
	const user = userState?.userDetail.data ? userState.userDetail.data : [];

	return (
		<div>
			<section className="jumbotron breadcumb no-bg">
				<div className="mainbreadcumb">
					<div className="container">
						<div className="row m-10-hor">
							<div className="col-12">
								<h1 className="text-center">Create User Account</h1>
							</div>
						</div>
					</div>
				</div>
			</section>

			<section className="container">
				{/*{Object.keys(formErrors).length === 0 && isSubmit ? <div>Create successful</div> : <pre>{JSON.stringify(formValues)}</pre>}*/}
				<div className="row">
					<div className="col-lg-7 offset-lg-1 mb-5">
						<form className="form-create-item" action="#">
							<div className="field-set">
								<h5>First Name</h5>
								<input type="text" name="fistname" className="form-control" value={formValues.firstname} onChange={handleChange} />

								<div className="spacer-10"></div>

								<h5>Last Name</h5>
								<input type="text" name="lastname" className="form-control" value={formValues.lastname} onChange={handleChange} />

								<div className="spacer-10"></div>

								<p>{formErrors.username} </p>
								<h5>Username</h5>
								<input type="text" name="username" className="form-control" value={formValues.username} onChange={handleChange} />

								<div className="spacer-10"></div>

								<p> {formErrors.email} </p>
								<h5>Email</h5>
								<input type="text" name="email" className="form-control" value={formValues.email} onChange={handleChange} />

								<div className="spacer-10"></div>

								<h5>Bio</h5>
								<textarea data-autoresize name="bio" className="form-control" value={formValues.bio} onChange={handleChange}></textarea>

								<div className="spacer-10"></div>

								<button className="btn-main" onClick={handleSubmit}>
									Create Profile
								</button>
							</div>
						</form>
					</div>
				</div>
			</section>

			<Footer />
		</div>
	);
};

export default ProfileCreate;
