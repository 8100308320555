import React from 'react';
import { Link } from '@reach/router';

const Footer = () => (
  <footer className='footer-light'>
    <div className='container'>
      <div className='row'>
        <div className='col-md-3 col-sm-6 col-xs-1'>
          <div className='widget'>
            <h5>Marketplace</h5>
            <ul>
              <li>
                <Link to='/Marketplace'>All NFTs</Link>
              </li>
              <li>
                <Link to='/Marketplace/Maps'>Maps</Link>
              </li>
              <li>
                <Link to='/Marketplace/3D models'>3D Models</Link>
              </li>
              <li>
                <Link to='/Marketplace/Scripts'>Scripts</Link>
              </li>
              <li>
                <Link to='/Marketplace/GUIS'>GUIs</Link>
              </li>
              <li>
                <Link to='/Marketplace/Textures'>Textures</Link>
              </li>
              <li>
                <Link to='/Marketplace/Games'>Games</Link>
              </li>
            </ul>
          </div>
        </div>
        <div className='col-md-3 col-sm-6 col-xs-1'>
          <div className='widget'>
            <h5>Resources</h5>
            <ul>
              <li className='dev-links' onClick={() => window.open("https://www.roblox.com/", "_self")} >Roblox</li>
              <li className='dev-links' onClick={() => window.open("https://developer.roblox.com", "_self")}>Roblox Developers</li>
            </ul>
          </div>
        </div>
        <div className='col-md-3 col-sm-6 col-xs-1'>
          <div className='widget'>
            <h5>Community</h5>
            <ul>
              <li>
                <Link to=''>Discord</Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div className='subfooter'>
      <div className='container'>
        <div className='row'>
          <div className='col-md-12'>
            <div className='de-flex'>
              <div className='de-flex-col'>
                <span onClick={() => window.open("", "_self")}>
                  <span className='copy'>&copy; Copyright 2021 - Blockchain Blox by Roninware</span>
                </span>
              </div>
              <div className='de-flex-col'>
                <div className='social-icons'>
                  <span onClick={() => window.open("https://discord.gg/2ZAWqVnvWM", "_self")}>
                    <i className='fab fa-discord fa-lg'></i>
                  </span>
                  <span onClick={() => window.open("", "_self")}>
                    <i className='fab fa-twitter fa-lg'></i>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </footer>
);
export default Footer;