export const categories = [
  {
    value: "maps",
    label: "Maps",
    icon: "fas fa-map-marked-alt",
  },
  {
    value: "3d models",
    label: "3D Models",
    icon: "fas fa-robot",
  },
  {
    value: "scripts",
    label: "Scripts",
    icon: "fa fa-file-code",
  },
  {
    value: "guis",
    label: "GUIs",
    icon: "fas fa-list-ul",
  },
  {
    value: "textures",
    label: "Textures",
    icon: "fas fa-images",
  },
  {
    value: "games",
    label: "Games",
    icon: "fa fa-gamepad",
  },
];

export const status = [
    {
        value: 'buy_now',
        label: 'Buy Now'
    },
    {
        value: 'on_auction',
        label: 'On Auction'
    },
    {
        value: 'has_offers',
        label: 'Has Offers'
    },
];

export const itemsType = [
    {
        value: 'single_items',
        label: 'Single Items'
    },
    {
        value: 'bundles',
        label: 'Bundles'
    }
];

export const collections = [
    {
        value: 'abstraction',
        label: 'Abstraction'
    },
    {
        value: 'patternlicious',
        label: 'Patternlicious'
    },
    {
        value: 'skecthify',
        label: 'Skecthify'
    },
    {
        value: 'cartoonism',
        label: 'Cartoonism'
    },
    {
        value: 'virtuland',
        label: 'Virtuland'
    },
    {
        value: 'papercut',
        label: 'Papercut'
    }
];