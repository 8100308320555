import React from 'react';
import SliderCarouselRedux from '../components/SliderCarouselRedux';
import CarouselCollectionRedux from '../components/CarouselCollectionRedux';
import Footer from '../components/Footer';
import { createGlobalStyle } from 'styled-components';
import Marketplace from './Marketplace';
import { categories } from '../components/constants/filters';
import { Link } from "@reach/router";


const GlobalStyles = createGlobalStyle`
  header#myHeader.navbar .search #quick_search{
    background: rgba(255, 255, 255, .1);
  }
  header#myHeader .logo .d-block{
    display: none !important;
  }
  header#myHeader .logo .d-none{
    display: none !important;
  }
  header#myHeader .logo .d-3{
    display: block !important;
  }
  footer.footer-light .subfooter span img.d-1{
    display: none !important;
  }
  footer.footer-light .subfooter span img.d-3{
    display: inline-block !important;
  }
  .de_countdown{
    right: 10px;
  }
  .author_list_pp{
    margin-left:0;
  }
  footer.footer-light .subfooter{
    border-top: 1px solid rgba(255,255,255,.1);
  }
`;

const NavLink = (props) => (
  <Link
    {...props}
    getProps={({ isCurrent }) => {
      // the object returned here is passed to the
      // anchor element's props
      return {
        className: isCurrent ? "active" : "non-active",
      };
    }}
  />
);

const Home = () => (
  <div>
    <GlobalStyles />
    <section className='jumbotron no-bg no-bottom'>
      <div className='container-fluid'>
        <div className='row'>
          <SliderCarouselRedux />
        </div>
      </div>
    </section>

    <section className='container no-bottom'>
      <div className='row'>
        <div className='col-lg-12 text-center'>
          <h2 className='style-2'>Browse Category</h2>
          <div className='small-border'></div>
        </div>
      </div>
      <div className='container px-0'>
        <div className='row'>
          {categories.map((item, index) => (
            <div className='col-lg-2 col-sm-4 col-6 mb30'>
              <NavLink to={"/Marketplace/" + item.value} catagory={item.value} style={{ textDecoration: "none" }}>
                <span className='box-url'>
                  <i className={item.icon}></i>
                  <h4>{item.label}</h4>
                </span>
              </NavLink>
            </div>
          ))}
        </div>
      </div>
    </section>

    <section className='container no-top'>
      <div className='row'>
        <div className='col-lg-12 text-center'>
          <h2 className='style-2'>Hot Collections</h2>
          <div className='small-border'></div>
        </div>
      </div>
      <div className='container px-0'>
        <div className='row'>
          <div className='col-lg-12 px-0'>
            <CarouselCollectionRedux />
          </div>
        </div>
      </div>
    </section>

    <Footer />
  </div>
);
export default Home;