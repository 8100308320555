import { Axios, Canceler } from '../../../core/axios';
import * as actions from '../../actions';
import { gql } from "graphql-tag"

const USER_QUERY = gql`query GetUser($wallet: String!) {
    user(where: {wallet: {eq: $wallet}}) {
        userid
        wallet
        profileImgSrc
        username
        firstname
        lastname
        password
        lastLogin
        email
    }
    }`;

const BALANCE_QUERY = gql`query GetBalance($wallet: String!) {
    userBalance(wallet: $wallet) 
    }`;

export const fetchUser = (wallet) => async (dispatch, getState, { client }) => {
  
  //access the state
  const state = getState();
  console.log(state);

  dispatch(actions.getUserDats.request(Canceler.cancel));

  try {

      //Set the data const using apollo client
      //console.log("client:", client);
      const data = await client.query("user", USER_QUERY, { wallet: wallet });
      //console.log("user_query data:", data);
      dispatch(actions.getUserDats.success(data[0]));

  } catch (err) {
      console.log("user_query error:", err);
      dispatch(actions.getUserDats.failure(err));
  }
};

//************************** Create or Update User:

const USER_UPDATE = gql`mutation updateUser($newUser: AddUpdateUserInput!) {
        addUpdateUser(input: $newUser) {
            user {
                userid
                username
                wallet
                firstname
                lastname
                email
                profileImgSrc
                lastLogin
            }
        }
    }`;

export const updateCreateUser = (user) => async (dispatch, getState, { client }) => {

    //access the state
    const state = getState();
    console.log(state);

    dispatch(actions.updateUser.request(Canceler.cancel));

    try {

        //Set the data const using apollo client
        //console.log("client:", client);
        const data = await client.query("addUpdateUser", USER_UPDATE, { newUser: user });
        //console.log("user_query data:", data);
        dispatch(actions.updateUser.success(data[0]));

    } catch (err) {
        console.log("user_update error:", err);
        dispatch(actions.updateUser.failure(err));
    }
};

//**************** Upload Profile pic ********************************

const UPLOAD_PIC = gql`mutation UploadProfilePic($file: UploadProfilePicInput!) {
    uploadProfilePic(input: $file) {
        boolean
    }
    }`;

export const uploadProfilePic = (fileUploadInput) => async (dispatch, getState, { client }) => {
    //alert(fileUploadInput);
    //access the state
    const state = getState();
    console.log(state);

    //dispatch(actions.uploadProfilePic.request(Canceler.cancel));

    try {

        //Set the data const using apollo client
        //console.log("client:", client);
        const data = await client.query("uploadProfilePic", UPLOAD_PIC, { file: fileUploadInput });
        //console.log("user_query data:", data);
        //dispatch(actions.uploadProfilePic.success(data[0]));

    } catch (err) {
        console.log("user_update error:", err);
        //dispatch(actions.uploadProfilePic.failure(err));
    }
};

//******************** Get user wallet balance:

export const fetchUserBalance = (wallet) => async (dispatch, getState, { client }) => {

    dispatch(actions.getUserBalance.request(Canceler.cancel));

    try {

        //Set the data const using apollo Query
        console.log("getUserBalance");
        const data = await client.query("userBalance", BALANCE_QUERY, { wallet: wallet });
        console.log("user_balance data:", data);
        dispatch(actions.getUserBalance.success(data));

    } catch (err) {
        dispatch(actions.getUserBalance.failure(err));
    }
};


