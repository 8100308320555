import React from 'react'
import { useQuery } from '@apollo/react-hooks';
import gql from "graphql-tag";
import { createGlobalStyle } from 'styled-components';
import Footer from '../components/Footer';
import { Link } from '@reach/router';
import ItemDetailRedux from './ItemDetailRedux';

const GlobalStyles = createGlobalStyle`
  .navbar {
   background: #FAF6F1;
  }
`;

const About = () => (
    <div>
        <GlobalStyles />

        <section className='jumbotron breadcumb no-bg'>
            <div className='mainbreadcumb'>
                <div className='container'>
                    <div className='row'>
                        <div className="col-md-8 offset-md-2 text-center">
                            <h1>About/FAQs</h1>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section className='container'>
            <div className="row">
                <div className="col-lg-4 col-md-6 mb-4">
                    <div className="feature-box f-boxed style-3 text-center">
                        <div className="text">
                            <h4>Getting Started</h4>
                            <p>Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam.</p>
                            <Link to="" className="btn-main m-auto">Read more</Link>
                        </div>
                    </div>
                </div>

                <div className="col-lg-4 col-md-6 mb-4">
                    <div className="feature-box f-boxed style-3 text-center">
                        <div className="text">
                            <h4>Buying</h4>
                            <p>Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam.</p>
                            <Link to="" className="btn-main m-auto">Read more</Link>
                        </div>
                    </div>
                </div>

                <div className="col-lg-4 col-md-6 mb-4">
                    <div className="feature-box f-boxed style-3 text-center">
                        <div className="text">
                            <h4>Selling</h4>
                            <p>Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam.</p>
                            <Link to="" className="btn-main m-auto">Read more</Link>
                        </div>
                    </div>
                </div>

                <div className="col-lg-4 col-md-6 mb-4">
                    <div className="feature-box f-boxed style-3 text-center">
                        <div className="text">
                            <h4>Creating</h4>
                            <p>Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam.</p>
                            <Link to="" className="btn-main m-auto">Read more</Link>
                        </div>
                    </div>
                </div>

                <div className="col-lg-4 col-md-6 mb-4">
                    <div className="feature-box f-boxed style-3 text-center">
                        <div className="text">
                            <h4>Partners</h4>
                            <p>Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam.</p>
                            <Link to="" className="btn-main m-auto">Read more</Link>
                        </div>
                    </div>
                </div>

                <div className="col-lg-4 col-md-6 mb-4">
                    <div className="feature-box f-boxed style-3 text-center">
                        <div className="text">
                            <h4>Developers</h4>
                            <p>Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam.</p>
                            <Link to="" className="btn-main m-auto">Read more</Link>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <Footer />
    </div>

);
export default About;

// const NFT_QUERY = gql`
// {
//   nftsCreated(first: 5) {
//     id
//     poolCount
//     txCount
//     totalVolumeUSD
//   }
// }
// `;

// function GetNFT() {
//     const { data, loading, error } = useQuery(NFT_QUERY);

//     if (loading) return <p>Loading...</p>;
//     if (error) return <p>Error</p>;

//     return (
//         <div className="nftinfo">
//           <header className="nftHeader">
//               <div>
//                 {data.nftsCreated.map((nfts) => {
//                   return (
//                     <p>
//                       {nfts.id} {nfts.poolCount} {nfts.txCount} {nfts.totalVolumeUSD}
//                     </p>
//                   );
//                 })}
//               </div>
//           </header>
//         </div>
//       );
// };

// export default GetNFT();
