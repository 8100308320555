import Footer from "../../components/Footer";
import React from "react";
import { useEffect, useState } from "react";
import { useLocation, Link } from "@reach/router";
import { useSelector } from "react-redux";
import * as selectors from "../../../store/selectors";
import * as actions from "../../../store/actions/thunks";
import ProfileComponent from "../../components/ProfileComponent";

const Settings = () => {
	const [showform, btn_show_form] = useState(false);
	const userState = useSelector(selectors.usersState);
	console.log("userState:", userState);
	const user = userState?.userDetail.data ? userState.userDetail.data : [];

	const location = useLocation();

	//destructuring pathname from location
	const { pathname } = location;

	//Javascript split method to get the name of the path in array
	const splitLocation = pathname.split("/");

	return (
		<div>
			<section className="container">
				<div className="view-account">
                    <div className="side-bar">
                        <div className="user-info">
                            <img className="img-profile img-circle img-responsive center-block" src={user?.profileImgSrc} alt="" />
                            <ul className="meta list list-unstyled">
                                <h3 className="name">{user.username}</h3>
                                <li className="email">
                                    <b>{user.wallet} </b>
                                </li>
                                <li className="activity">Last logged in: Today at 2:18pm</li>
                            </ul>
                        </div>
                        <nav className="side-menu">
                            <ul className="nav">
                                <li className={splitLocation[1] === "Profile" ? "active" : ""}>
                                    <Link to="/Profile">
                                        <span className="fa fa-user"></span> Profile
                                    </Link>
                                </li>
                                <li className={splitLocation[1] === "CryptoAssets" ? "active" : ""}>
                                    <Link to="/CryptoAssets">
                                        <span className="fa fa-envelope"></span> NFTs
                                    </Link>
                                </li>
                                <li className={splitLocation[1] === "Statistics" ? "active" : ""}>
                                    <Link to="/Statistics">
                                        <span className="fa fa-th"></span> Statistics
                                    </Link>
                                </li>
                                <li className={splitLocation[1] === "Settings" ? "active" : ""}>
                                    <Link to="/Settings">
                                        <span className="fa fa-cog"></span> Settings
                                    </Link>
                                </li>
                            </ul>
                        </nav>
                    </div>

                    <div className="content-panel">
                        <h2 className="title">Settings</h2>
                        <br />
                        <section className="container">
                            <div className="newlayo">
                                <b>Wallet address:</b> {user.wallet} <br />
                                <b>User id:</b> {user.userid} <br />
                                <b>Username:</b> {user.username} <br />
                                <b>Last login:</b> {user.lastLogin} <br />
                                <b>Email:</b> {user.email} <br />
                            </div>
                        </section>
                    </div>
                </div>
			</section>

			<Footer />
		</div>
	);
};
export default Settings;
