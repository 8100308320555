import React, { useEffect, useState } from "react";
import Footer from '../components/Footer';
import { categories, collections } from '../components/constants/filters';
import { useSelector, useDispatch } from 'react-redux';
import * as selectors from '../../store/selectors';

const Createpage = (props) => {


  const initialValues = ({
    art: [],
    asset: [],
    title: "",
    extlink: "",
    description: "",
    collection: "",
    category: "",
    properties: [],
    price: null,
    royalties: null,
    supply: null,
  });

  const [formValues, setFormValues] = useState(initialValues);
  const [formErrors, setFormErrors] = useState(initialValues);
  const [isSubmit, setIsSubmit] = useState(false);
  const [status, setStatus] = useState(0) // 0: no show, 1: show yes.
  const [propertyValues, setPropertyValues] = useState(formValues.properties)

  const radioHandler = (status) => {
      setStatus(status);
      if (status === 0) {
          setFormValues({ ...formValues, ['supply']: null });
      }
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
  };

  const handlePropertyChange = (i, e) => {
    const newFormValues = [...propertyValues];
    newFormValues[i][e.target.name] = e.target.value;
    setPropertyValues(newFormValues);
    setFormValues({...formValues, ["properties"]: newFormValues});
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setFormErrors(validate(formValues));
    setIsSubmit(true);

  };

  const addFormFields = () => {
    setPropertyValues([...propertyValues, {}]);
  };

  const removeFormFields = (i) => {
    const newPropertyValues = [...propertyValues];
    newPropertyValues.splice(i, 1);
    setPropertyValues(newPropertyValues);
    setFormValues({...formValues, ["properties"]: newPropertyValues});
  };

  useEffect(() => {
    console.log(formErrors);
    if (Object.keys(formErrors).length === 0 && isSubmit) {
      console.log(formValues);
    }
  });



  const [selectedFile, setSelectedFile] = useState();
  const [preview, setPreview] = useState();

  useEffect(() => {
    if (!selectedFile) {
      setPreview(undefined);
      return;
    }
    // create the preview
    const objectUrl = URL.createObjectURL(selectedFile);
    setPreview(objectUrl);

    // free memory when ever this component is unmounted
    return () => URL.revokeObjectURL(objectUrl);
  }, [selectedFile]);


  const onSelectFile = (e) => {
    if (!e.target.files || e.target.files.length === 0) {
      setSelectedFile(undefined);
      return;
    }
    setSelectedFile(e.target.files[0]);
    setFormValues({...formValues, ["art"]: e.target.value});
  };



  const validate = (values) => {
    const errors = {};
    if (values.art.length < 1) {
      errors.art = "Art preview image is required";
    }
    if (values.asset.length < 1) {
      errors.asset = "Asset file is required";
    }
    if (!values.title) {
      errors.title = "Title required";
    }
    if (!values.extlink) {
      errors.extlink = "Please enter a valid link";
    }
    if (values.description.length < 5) {
      errors.description = "Please use more than 5 characters";
    }
    if (values.collection === "") {
      errors.collection = "Please select a collection";
    }
    if (values.category === "") {
      errors.category = "Please select a category";
    }
    if (values.properties.length < 1 && values.properties.value) {
      errors.properties = "Art preview";
    }
    if (values.price === null) {
      errors.price = "Enter a price";
    } else if (values.price < 0) {
      errors.price = "Enter a valid price"
    }
      
    if (values.royalties === null || values.royalties > 100 || values.royalties < 0) {
      errors.royalties = "Royalty % must be between 0 and 100";
    }
    if (values.supply !== null && (values.supply > 50000 || values.supply < 1)) {
      errors.supply = "Supply must be at least 1 and can not exceed 50,000";
    }
    if (Object.keys(errors).length > 0) {
      alert("Please correct the errors and resubmit");
    }
    
    return errors;
  };

//Load user state

    const dispatch = useDispatch();
    const userState = useSelector(selectors.usersState);
    console.log("userState:", userState);
    const user = userState?.userDetail.data ? userState.userDetail.data : [];


  return (
    <div>
      <section className='jumbotron breadcumb no-bg'>
        <div className='mainbreadcumb'>
          <div className='container'>
            <div className='row m-10-hor'>
              <div className='col-12'>
                <h1 className='text-center'>Create NFT</h1>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className='container'>
        {/*{Object.keys(formErrors).length === 0 && isSubmit ? <div>Create successful</div> : <pre>{JSON.stringify(formValues)}</pre>}*/}
        <div className='row'>
          <div className='col-lg-7 offset-lg-1 mb-5'>
            <form className='form-create-item' action='#'>
              <div className='field-set'>
                <h5>Upload Art</h5>

                <div className='d-create-file'>
                  <p className='file_name'>PNG, JPG, GIF, WEBP or MP4. Max 200mb. {formValues.art} </p>
                  <div className='browse'>
                    <button className='btn-main'>Browse</button>
                    <input type='file' name='art' className='upload_file' value={formValues.art} onChange={onSelectFile} />
                  </div>
                </div>

                <p> {formErrors.art} </p>
                <div className='spacer-single'></div>

                <h5>Upload Assets</h5>
                <div className='d-create-file'>
                  <p id='file_name'>PNG, JPG, GIF, WEBP or MP4. Max 200mb. {formValues.asset} </p>
                  <div className='browse'>
                    <button className='btn-main'>Browse</button>
                    <input type='file' name='asset' className='upload_file' value={formValues.asset} onChange={handleChange} />
                  </div>
                </div>

                <p> {formErrors.asset} </p>
                <div className='spacer-single'></div>

                <h5>Title</h5>
                <input type='text' name='title' className='form-control' placeholder='e.g. Crypto Funk' value={formValues.title} onChange={handleChange} />

                <p> {formErrors.title} </p>
                <div className='spacer-10'></div>

                <h5>External Link</h5>
                <input type='text' name='extlink' className='form-control' placeholder='e.g. blockchainblox.com' value={formValues.extlink} onChange={handleChange} />

                <p> {formErrors.extlink} </p>
                <div className='spacer-10'></div>

                <h5>Description</h5>
                <textarea data-autoresize name='description' className='form-control' placeholder='e.g. "This is very limited item"' value={formValues.description} onChange={handleChange}></textarea>

                <p> {formErrors.description} </p>
                <div className='spacer-10'></div>

                <h5>Collection</h5>
                <select name='collection' className='form-control' value={formValues.collection} onChange={handleChange}>
                  <option value=''>Collection</option>
                  {collections.map((item, index) => (
                    <option value={item.value}>{item.value}</option>
                  ))}
                </select>

                <p> {formErrors.collection} </p>
                <div className='spacer-10'></div>

                <h5>Category</h5>
                <select name='category' value={formValues.category} className='form-control' onChange={handleChange}>
                  <option value=''>Select a Category</option>
                  {categories.map((item, index) => (
                    <option value={item.value}>{item.value}</option>
                  ))}
                </select>
                <p> {formErrors.category} </p>
                <div className='spacer-10'></div>

                <div className='properties-container'>
                  <h5>Properties</h5>
                  {propertyValues.map((element, index) => (
                    <div className='form-control' key={index}>
                      <label>Name</label>
                      <input type='text' required name='nam' value={element.nam} onChange={(e) => handlePropertyChange(index, e)} />
                      <label>Value</label>
                      <input type='text' required name='value' value={element.value} onChange={(e) => handlePropertyChange(index, e)} />

                      <button type='button' className='button remove' onClick={() => removeFormFields(index)}>
                        Remove
                      </button>
                    </div>
                  ))}
                  <div className='button-section'>
                    <button className='button add' type='button' onClick={() => addFormFields()}>
                      Add
                    </button>
                  </div>
                  {/* 
                  <input type='text' name='properties' className='form-control' placeholder='e.g. model, size, type' value={formValues.properties} onChange={handleChange} /> 

                  <div className='row'>
                    <div className='col-lg-2 col-sm-4 col-6 mb20'>
                      <span className='box1'>{formValues.properties}</span>
                    </div>
                  </div>*/}
                </div>

                <p> {formErrors.properties} </p>
                <div className='spacer-10'></div>

                <h5>Price</h5>
                <input type='number' name='price' className='form-control' placeholder='enter price for one item (ETH)' value={formValues.price} onChange={handleChange} />

                <p> {formErrors.price} </p>
                <div className='spacer-10'></div>

                <h5>Royalties</h5>
                <input type='number' name='royalties' className='form-control' placeholder='suggested: 0, 10%, 20%, 30%. Maximum is 100%' value={formValues.royalties} onChange={handleChange} />

                <p> {formErrors.royalties} </p>
                <div className='spacer-10'></div>

                <h5>Supply</h5>

                <div>
                  <input type='radio' name='supplyshow' value='0' className='form-checkbox' checked={status === 0} onClick={(e) => radioHandler(0)} />
                  <span> Infinite</span>
                  <br />
                  <input type='radio' className='form-checkbox' name='supply' value={formValues.supply} checked={status === 1} onClick={(e) => radioHandler(1)} />
                  <span> Set #</span>
                  <input
                    type='number'
                    style={{display: status === 0 ? "none" : "inline"}}
                    name='supply'
                    className='form-control'
                    placeholder='min: 1, max: 50,000'
                    value={formValues.supply}
                    onChange={handleChange}
                  />
                </div>

                <p> {formErrors.supply} </p>
                <div className='spacer-10'></div>

                <button className='btn-main' onClick={handleSubmit}>
                  Create NFT
                </button>
              </div>
            </form>
          </div>

          <div className='col-lg-3 col-sm-6 col-xs-12'>
            <h5>Preview item</h5>
            <div className='nft__item m-0'>
              <div className='author_list_pp'>
                <span>
                                  <img className='lazy' src={user !== undefined ? "." + user?.profileImgSrc : "./img/author/author-5.jpg"} /* ''  {user.wallet.profile-pic} */ alt='' />
                  <i className='fa fa-check'></i>
                </span>
              </div>
              <div className='nft__item_wrap'>
                <span>{selectedFile && <img src={preview} className='lazy nft__item_preview' alt='' />}</span>
              </div>
              <div className='nft__item_info'>
                <span>
                  <h4>{formValues.title}</h4>
                </span>
                <div className='nft__item_price'>
                  <span>{formValues.price === null ? "0.004" : formValues.price} eth</span>
                </div>
                {/* <div className='nft__item_like'>
                  <i className='fa fa-heart'></i>
                  <span>50</span>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </div>
  );
};

export default Createpage;