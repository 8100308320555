import React from "react";
import { Router, Location, Redirect } from "@reach/router";
import ScrollToTopBtn from "./components/Navbar/ScrollToTop";
import Header from "./components/Navbar/index";
import Home from "./components/pages/Home";
import Marketplace from "./components/pages/Marketplace";
import CreateNFT from "./components/pages/Create";
import Wallet from "./components/pages/Wallet";
import About from "./components/pages/About";
import Explore from "./components/pages/Explore";
import ItemDetailRedux from './components/pages/ItemDetailRedux';

import { createGlobalStyle } from "styled-components";
import Profile from "./components/pages/Profile/Profile";
import AdminPage from "./components/pages/AdminPage";
import ProfileCreate from "./components/pages/profileCreate";
import Settings from "./components/pages/Profile/Settings";
import CryptoAssets from "./components/pages/Profile/CryptoAssets";
import Statistics from "./components/pages/Profile/Statistics";

const GlobalStyles = createGlobalStyle`
  :root {
    scroll-behavior: unset;
  }
`;

export const ScrollTop = ({ children, location }) => {
  React.useEffect(() => window.scrollTo(0, 0), [location]);
  return children;
};

const PosedRouter = ({ children }) => (
  <Location>
    {({ location }) => (
      <div id="routerhang">
        <div key={location.key}>
          <Router location={location}>{children}</Router>
        </div>
      </div>
    )}
  </Location>
);

const App = () => (
	<div className="wrapper">
		<GlobalStyles />
		<Header />
		<PosedRouter>
			<ScrollTop path="/">
				<Home exact path="/">
					<Redirect to="/Home" />
				</Home>
				<Explore path="/Explore" />
				<About path="/About" />
				<Marketplace path="/Marketplace" />
				<Marketplace path={`/Marketplace/:catagory`} />
				<ItemDetailRedux path="/ItemDetail/:nftId" />
				<CreateNFT path="/CreateNFT" />
				<Wallet path="/wallet" />
				<Profile path="/Profile" />
				<ProfileCreate path="/createProfile" />
				<Settings path="/Settings" />
				<CryptoAssets path="/CryptoAssets" />
				<Statistics path="/Statistics" />
			</ScrollTop>
		</PosedRouter>
		<ScrollToTopBtn />
	</div>
);

export default App;
