import React from 'react';
import { createGlobalStyle } from 'styled-components';
import Footer from '../components/Footer';
const GlobalStyles = createGlobalStyle`
  .mainside{
    .connect-wal{
      display: none;
    }
    .logout{
      display: flex;
      align-items: center;
    }
  }
`;

const Wallet = () => (
  <div>
  <GlobalStyles />
    <section className='jumbotron breadcumb no-bg'>
      <div className='mainbreadcumb'>
        <div className='container'>
          <div className='row m-10-hor'>
            <div className='col-12'>
              <h1 className='text-center'>Connect Metamask Wallet</h1>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section className='container'>
      <div className='row'>
        <h1>tbd</h1>
      </div>
    </section>

    <Footer />
  </div>
);

export default Wallet;
